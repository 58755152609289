<template>
  <div>
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
        <!-- TAHUN -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('globalSingular.year')"
              label-for="period"
            >
                 <v-select
                    inputId="period"
                    class="select-size-sm"
                    v-model="form.year"
                    :options="LOV.year"
                 />
            </b-form-group>
          </b-col>
          <!-- BULAN  -->
          <b-col cols="12" md="4">
                <b-form-group
                  :label="$t('globalSingular.month')"
                  label-for="period"
                >
                <v-select
                    inputId="period"
                    class="select-size-sm"
                    v-model="form.month"
                    :options="LOV.month"
                    :reduce="field => field.value"
                  />
                </b-form-group>
          </b-col>
          <!-- OFFICE  -->
          <b-col cols="12" md="4">
            <b-form-group
                  :label="$t('globalSingular.branch')"
                  label-for="period"
            >
                <v-select
                    inputId="branch"
                    class="select-size-sm"
                    v-model="form.officeId"
                    :options="LOV.office"
                    label="label"
                    :reduce="field => field.id"
                />
            </b-form-group>
          </b-col>
          <!-- Button Filter -->
          <b-col cols="12" md="3">
             <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <div class="mt-1 mb-1 d-flex justify-content-end">
        <!-- Cetak  -->
      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>
      <b-button-group class="ml-1">
         <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>
    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportCashBankSummary.singular.cashBankReportSummary') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

      <!-- table  -->
      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists"
        :filter="searchQuery"
        responsive
        primary-key="id"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

      <template #table-busy>
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- periode  -->
        <template #cell(periode)="{ item }">
          <span :class="(item.periode === 'Total') ? ['text-right','font-weight-bolder','font-italic'] : '' ">
            {{ item.periode }}
           </span>
        </template>

         <!-- Branch  -->
        <template #cell(branch)="{ item }">
          <span :class="(item.periode === 'Total') ? ['text-right', 'font-weight-bolder','font-italic'] : '' ">
            {{ item.branch }}
            <br>
           </span>
        </template>

         <!-- Branch  -->
        <template #cell(balance)="{ item }">
          <span :class="(item.periode === 'Total') ? ['text-right', 'font-weight-bolder','font-italic'] : '' ">
            {{ formatCurrency(item.balance, false) }}
            <br>
           </span>
        </template>

        <!-- Branch  -->
        <template #cell(droping)="{ item }">
          <span :class="(item.periode === 'Total') ? ['text-right', 'font-weight-bolder','font-italic'] : '' ">
            {{ formatCurrency(item.droping, false) }}
            <br>
           </span>
        </template>

        <!-- Request  -->
        <template #cell(request)="{ item }">
          <span :class="['d-block', 'text-nowrap', 'text-right', (item.periode === 'Total') ? ['font-weight-bolder','font-italic'] : '' ]">
            {{ formatCurrency(item.request, false) }}
            <br>
           </span>
        </template>

        <!-- Outstanding  -->
        <template #cell(outstanding)="{ item }">
          <span :class="(item.periode === 'Total') ? ['text-right', 'font-weight-bolder','font-italic'] : '' ">
            {{ formatCurrency(item.outstanding, false) }}
            <br>
           </span>
        </template>

      </b-table>

    </b-card>
  </div>
</template>
<script>

import {
    BForm,
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BFormGroup,
    BCardTitle,
    BCardSubTitle,
    BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency } from '@/utils/formatter'



export default {
  components: {
    vSelect,
    BForm,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BSpinner
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { $get } = useHttp()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const form = ref({
      year: '2022',
      month: '',
      officeId: ''
    })

      const LOV = ref({
        office: [],
        year: [
         2021,
         2022,
         2023,
         2024
       ],
       month: [
            {
                label: 'January',
                value: 1
            },
            {
                label: 'February',
                value: 2
            },
            {
                label: 'March',
                value: 3
            },
            {
                label: 'April',
                value: 4
            },
            {
                label: 'May',
                value: 5
            },
            {
                label: 'June',
                value: 6
            },
            {
                label: 'July',
                value: 7
            },
            {
                label: 'Agust',
                value: 8
            },
            {
                label: 'September',
                value: 9
            },
            {
                label: 'October',
                value: 10
            },
            {
                label: 'November',
                value: 11
            },
            {
                label: 'December',
                value: 12
            }
       ],
    })

    const columnToBeFilter = []
    const tableColumns = [
      { key: 'periode', label: 'Periode' },
      { key: 'branch', label: 'Branch' },
      { key: 'balance', label: 'Balance', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'droping', label: 'Droping', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'request', label: 'Request', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'outstanding', label: 'Outstanding', thClass: 'text-right', tdClass: 'text-right' }

    ]

    const getOffice = async () => {
      const { data } = await $get({ url: 'master/offices'})
      LOV.value.office = data
    }

    const periodReportText = ref()

    const updatePeriodText = () => {
        let valMonth    = form.value.month - 1
        const Month     = form.value.month != '' ? LOV.value.month[valMonth].label : ' All'
        const Year      = form.value.year  != '' ? form.value.year : '-'

        periodReportText.value = `${Month}  ${Year}`

    }

    onMounted(async () => {
     await getOffice()

     const params = [
        `year=${form.value.year}`,
        `month=${form.value.month}`,
        `officeId=${form.value.officeId}`
      ]

      const url = `reports/cashbank-summary?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
      
    })
    return {
        form,
        LOV,
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        columnToBeFilter,
        tableColumns,
        formatCurrency,
        periodReportText,
        updatePeriodText
    }
  },
  methods: {
    getReport () {
      const params = [
        `year=${this.form.year}`,
        `month=${this.form.month}`,
        `officeId=${this.form.officeId}`
      ]

      const url = `reports/cashbank-summary?${ params.join('&') }`
      this.fetchLists(url)
      this.updatePeriodText()
    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBankSummary.singular.cashBankReportSummary'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBankSummary.singular.cashBankReportSummary')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
     formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>


<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
